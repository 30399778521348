import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const PageDashboard = loadable(() => import("./PageDashboard.js"))
const PageVersionListDashboard = loadable(() => import("./PageVersionListDashboard.js"))
const PageDetailDashboard = loadable(() => import("./PageDetailDashboard.js"))


export default [
    <Route exact path="/admin/page/" element={<PageDashboard/>} />,
    <Route exact path="/admin/page/:pageID/" element={<PageVersionListDashboard/>} />,
    <Route exact path="/admin/page/:pageID/:versionID/" element={<PageDetailDashboard/>} />,
]