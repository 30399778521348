import React from 'react';
import {Route, Navigate} from 'react-router-dom';
import loadable from '@loadable/component'
const LandingPage = loadable(() => import("./LandingPage.js"));

export default [
    /*<Route path="/" element={ <Navigate to="/login/"/> } />,*/

    <Route exact path="/" element={<LandingPage/>} />,

    <Route exact path="/home-health-aide/" element={<LandingPage/>} />,
    <Route exact path="/patient-care-technician/" element={<LandingPage/>} />,
    <Route exact path="/certified-nursing-assistant/" element={<LandingPage/>} />,
    <Route exact path="/phlebotomy-technician/" element={<LandingPage/>} />,
    <Route exact path="/licensed-practical-nurse/" element={<LandingPage/>} />,
    <Route exact path="/registered-nurse/" element={<LandingPage/>} />,

    <Route exact path="/home-health-aide/job-market-data/" element={<LandingPage/>} />,
    <Route exact path="/patient-care-technician/job-market-data/" element={<LandingPage/>} />,
    <Route exact path="/certified-nursing-assistant/job-market-data/" element={<LandingPage/>} />,
    <Route exact path="/phlebotomy-technician/job-market-data/" element={<LandingPage/>} />,
    <Route exact path="/licensed-practical-nurse/job-market-data/" element={<LandingPage/>} />,
    <Route exact path="/registered-nurse/job-market-data/" element={<LandingPage/>} />,

    <Route exact path="/home-health-aide/program-course-outline/" element={<LandingPage/>} />,
    <Route exact path="/patient-care-technician/program-course-outline/" element={<LandingPage/>}/>,
    <Route exact path="/certified-nursing-assistant/program-course-outline/" element={<LandingPage/>}/>,
    <Route exact path="/phlebotomy-technician/program-course-outline/" element={<LandingPage/>}/>,
    <Route exact path="/licensed-practical-nurse/program-course-outline/" element={<LandingPage/>} />,
    <Route exact path="/registered-nurse/program-course-outline/" element={<LandingPage/>} />,

    <Route exact path="/train-with-us/" element={<LandingPage/>} />,
    <Route exact path="/how-it-works/" element={<LandingPage/>} />,
    <Route exact path="/testimonials/" element={<LandingPage/>} />,
    <Route exact path="/learner-support/" element={<LandingPage/>} />,
    <Route exact path="/career-services/" element={<LandingPage/>} />,

    <Route exact path="/sign-up/" element={<LandingPage/>} />,
    <Route exact path="/payment-options/" element={<LandingPage/>} />,
    <Route exact path="/mycaa-military/" element={<LandingPage/>} />,
    <Route exact path="/army-credentialing/" element={<LandingPage/>} />,
    <Route exact path="/advant-edge/" element={<LandingPage/>} />,
    <Route exact path="/extensions/" element={<LandingPage/>} />,

    <Route exact path="/events/" element={<LandingPage/>} />,
    <Route exact path="/blog/" element={<LandingPage/>} />,
    <Route exact path="/about-us/" element={<LandingPage/>} />,
    <Route exact path="/career-advice/" element={<LandingPage/>} />,
    <Route exact path="/success-stories/" element={<LandingPage/>} />,
    <Route exact path="/faqs/" element={<LandingPage/>} />,
    <Route exact path="/contact-us/" element={<LandingPage/>} />,

    <Route exact path="/apply-now/" element={<LandingPage/>} />,
    <Route exact path="/application-complete/" element={<LandingPage/>} />,
    <Route exact path="/terms-of-use/" element={<LandingPage/>} />,
    <Route exact path="/privacy-policy/" element={<LandingPage/>} />,
    <Route exact path="/refund-policy/" element={<LandingPage/>} />,
]