import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'



export const fetchPrograms = createAsyncThunk(
  'fetchPrograms',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/program/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchModules = createAsyncThunk(
  'fetchModules',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/program_module/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const saveModule = createAsyncThunk(
  'saveModule',
  async (module, thunkAPI) => {
    const saveUrl = module.id ? `/api/program_module/${module.id}/` : `/api/program_module/`
    const saveMethod = module.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(module)
    })
    const data = await response.json()
    return data
  }
)



export const fetchModuleSkills = createAsyncThunk(
  'fetchModuleSkills',
  async (moduleID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/program_module/${moduleID}/fetch_skills/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const saveModuleSkills = createAsyncThunk(
  'saveModuleSkills',
  async ({moduleID, skills}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/program_module/${moduleID}/save_skills/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({skills})
    })
    const data = await response.json()
    return data
  }
)



export const saveDocumentRequest = createAsyncThunk(
  'saveDocumentRequest',
  async (documentRequest, thunkAPI) => {
    const saveUrl = documentRequest.id ? `/api/document_request/${documentRequest.id}/` : `/api/document_request/create_doc_request/`
    const saveMethod = documentRequest.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(documentRequest)
    })
    const data = await response.json()
    return data
  }
)



export const deleteDocumentRequest = createAsyncThunk(
  'deleteDocumentRequest',
  async (documentRequestID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/document_request/${documentRequestID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data

  }

)

export const uploadStudentDocument = createAsyncThunk(
  'uploadStudentDocument',
  async ({documentRequestID, file}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const formData = new FormData()
    formData.append('uploadFile', file)
    formData.append('documentRequestID', documentRequestID)

    const response = await fetch(`/api/document_request/${documentRequestID}/upload_file/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)



export const saveProgram = createAsyncThunk(
  'saveProgram',
  async (program, thunkAPI) => {
    const saveUrl = program.id ? `/api/program/${program.id}/` : `/api/program/`
    const saveMethod = program.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(program)
    })
    const data = await response.json()
    return data
  }
)


export const deleteProgram = createAsyncThunk(
  'deleteProgram',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/program/${programID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)

////

export const fetchLectures = createAsyncThunk(
  'fetchLectures',
  async ({programID, cohortID}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    let query = programID ? `?program=${programID}` : ''
    query += cohortID ? `&cohort=${cohortID}` : ''

    const response = await fetch(`/api/lecture/${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveLecture = createAsyncThunk(
  'saveLecture',
  async (lecture, thunkAPI) => {
    const saveUrl = lecture.id ? `/api/lecture/${lecture.id}/` : `/api/lecture/`
    const saveMethod = lecture.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const formData = new FormData()
    formData.append('start_time', lecture.start_time)
    formData.append('end_time', lecture.end_time)
    formData.append('program', lecture.program)
    formData.append('module', lecture.module)
    formData.append('cohort', lecture.cohort)
    formData.append('name', lecture.name)
    formData.append('description', lecture.description)
    formData.append('meeting_url', lecture.meeting_url)

    if(lecture.video_file){
        formData.append('video', lecture.video_file)
    }

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)

export const deleteLecture = createAsyncThunk(
  'deleteLecture',
  async (lectureID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/lecture/${lectureID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)

// Clinical Evaluations
export const fetchClinicalEvals = createAsyncThunk(
  'fetchClinicalEvals',
  async (programStudentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const query = programStudentID ? `?program_student=${programStudentID}` : ''
    const response = await fetch(`/api/clinical_evaluation/${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveClinicalEval = createAsyncThunk(
  'saveClinicalEval',
  async (clinicalEval, thunkAPI) => {
    const saveUrl = clinicalEval.id ? `/api/clinical_evaluation/${clinicalEval.id}/` : `/api/clinical_evaluation/`
    const saveMethod = clinicalEval.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const formData = new FormData()
    formData.append('name', clinicalEval.name)
    formData.append('start_date', clinicalEval.start_date)
    formData.append('end_date', clinicalEval.end_date)
    formData.append('student', clinicalEval.student)
    formData.append('program_student', clinicalEval.program_student)

    if(clinicalEval.clinical_eval_file){
        formData.append('eval_form', clinicalEval.clinical_eval_file)
    }

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)

export const deleteClinicalEval = createAsyncThunk(
  'deleteClinicalEval',
  async (clinicalEvalID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/clinical_evaluation/${clinicalEvalID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



////
export const fetchCohorts = createAsyncThunk(
  'fetchCohorts',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const query = programID ? `?program=${programID}` : ''
    const response = await fetch(`/api/cohort/${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveCohort = createAsyncThunk(
  'saveCohort',
  async (cohort, thunkAPI) => {
    const saveUrl = cohort.id ? `/api/cohort/${cohort.id}/` : `/api/cohort/`
    const saveMethod = cohort.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(cohort)
    })
    const data = await response.json()
    return data
  }
)

export const deleteCohort = createAsyncThunk(
  'deleteCohort',
  async (cohortID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/cohort/${cohortID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



export const fetchBooks = createAsyncThunk(
  'fetchBooks',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/book/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveBook = createAsyncThunk(
  'saveBook',
  async (book, thunkAPI) => {
    const saveUrl = book.id ? `/api/book/${book.id}/` : `/api/book/`
    const saveMethod = book.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(book)
    })
    const data = await response.json()
    return data
  }
)

export const deleteBook = createAsyncThunk(
  'deleteBook',
  async (bookID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/book/${bookID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



export const fetchCourses = createAsyncThunk(
  'fetchCourses',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/course/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveCourse = createAsyncThunk(
  'saveCourse',
  async (course, thunkAPI) => {
    const saveUrl = course.id ? `/api/course/${course.id}/` : `/api/course/`
    const saveMethod = course.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(course)
    })
    const data = await response.json()
    return data
  }
)

export const deleteCourse = createAsyncThunk(
  'deleteCourse',
  async (courseID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/course/${courseID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchChapters = createAsyncThunk(
  'fetchChapters',
  async (bookID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/chapter/?book=${bookID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchProgramChapters = createAsyncThunk(
  'fetchProgramChapters',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/chapter/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)

export const saveChapter = createAsyncThunk(
  'saveChapter',
  async (chapter, thunkAPI) => {
    const saveUrl = chapter.id ? `/api/chapter/${chapter.id}/` : `/api/chapter/`
    const saveMethod = chapter.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(chapter)
    })
    const data = await response.json()
    return data
  }
)

export const deleteChapter = createAsyncThunk(
  'deleteChapter',
  async (chapterID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/chapter/${chapterID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)





export const fetchQuestions = createAsyncThunk(
  'fetchQuestions',
  async (chapterID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/question/?chapter=${chapterID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const saveQuestion = createAsyncThunk(
  'saveQuestion',
  async (question, thunkAPI) => {
    const saveUrl = question.id ? `/api/question/${question.id}/` : `/api/question/`
    const saveMethod = question.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(question)
    })
    const data = await response.json()
    return data
  }
)

export const deleteQuestion = createAsyncThunk(
  'deleteQuestion',
  async (questionID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/question/${questionID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchAssessments = createAsyncThunk(
  'fetchAssessments',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/assessment/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveAssessment = createAsyncThunk(
  'saveAssessment',
  async (assessment, thunkAPI) => {
    const saveUrl = assessment.id ? `/api/assessment/${assessment.id}/` : `/api/assessment/`
    const saveMethod = assessment.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(assessment)
    })
    const data = await response.json()
    return data
  }
)

export const deleteAssessment = createAsyncThunk(
  'deleteAssessment',
  async (assessmentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/assessment/${assessmentID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



//Video
export const fetchVideos = createAsyncThunk(
  'fetchVideos',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/video/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveVideo = createAsyncThunk(
  'saveVideo',
  async (video, thunkAPI) => {
    const saveUrl = video.id ? `/api/video/${video.id}/` : `/api/video/`
    const saveMethod = video.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(video)
    })
    const data = await response.json()
    return data
  }
)

export const deleteVideo = createAsyncThunk(
  'deleteVideo',
  async (videoID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/video/${videoID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


//Video - End
export const fetchLessons = createAsyncThunk(
  'fetchLessons',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/lesson/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveLesson = createAsyncThunk(
  'saveLesson',
  async (lesson, thunkAPI) => {
    const saveUrl = lesson.id ? `/api/lesson/${lesson.id}/` : `/api/lesson/`
    const saveMethod = lesson.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(lesson)
    })
    const data = await response.json()
    return data
  }
)

export const deleteLesson = createAsyncThunk(
  'deleteLesson',
  async (lessonID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/lesson/${lessonID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchProgramStudents = createAsyncThunk(
  'fetchProgramStudents',
  async ({programID, searchQuery, cohortFilter, moduleFilter, statusFilter}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const {studentPage} = thunkAPI.getState().admin.program
    let url = `/api/program_student/?program=${programID}`
    url += `&page=${studentPage ? studentPage : 1}`
    url += `&search=${searchQuery}`
    url += cohortFilter=='all' ? '' : `&cohorts=${cohortFilter}`
    url += statusFilter=='all' ? '' : `&is_active=${statusFilter=='active'}`
    url += moduleFilter && moduleFilter!='all' ? `&cohorts__module=${moduleFilter}` : ''

    const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentGrades = createAsyncThunk(
  'fetchStudentGrades',
  async (programStudent, thunkAPI) => {
    const saveUrl = `/api/program_student/${programStudent.id}/fetch_student_grades/`
    const saveMethod = 'GET'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentPractices = createAsyncThunk(
  'fetchStudentPractices',
  async (programStudent, thunkAPI) => {
    const saveUrl = `/api/program_student/${programStudent.id}/fetch_student_practices/`
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const saveProgramStudent = createAsyncThunk(
  'saveProgramStudent',
  async (programStudent, thunkAPI) => {
    const saveUrl = programStudent.id ? `/api/program_student/${programStudent.id}/update_student/` : `/api/program_student/create_student/`
    const saveMethod = programStudent.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(programStudent)
    })
    const data = await response.json()
    return data
  }
)


export const addStudentCohort = createAsyncThunk(
  'addStudentCohort',
  async ({programStudent, cohortID}, thunkAPI) => {
    const saveUrl = `/api/program_student/${programStudent.id}/add_student_cohort/`

    const response = await fetch(saveUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
        body: JSON.stringify({cohortID})
    })
    const data = await response.json()
    return data
  }
)


export const updateStudentCohort = createAsyncThunk(
  'updateStudentCohort',
  async ({programStudent, cohortID, newCohortID}, thunkAPI) => {
    const saveUrl = `/api/program_student/${programStudent.id}/update_student_cohort/`

    const response = await fetch(saveUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
        body: JSON.stringify({cohortID, newCohortID})
    })
    const data = await response.json()
    return data
  }
)


export const removeStudentCohort = createAsyncThunk(
  'removeStudentCohorts',
  async ({programStudent, cohortID}, thunkAPI) => {
    const saveUrl = `/api/program_student/${programStudent.id}/remove_student_cohorts/`

    const response = await fetch(saveUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
        body: JSON.stringify({cohortID})
    })
    const data = await response.json()
    return data
  }
)


export const deleteProgramStudent = createAsyncThunk(
  'deleteProgramStudent',
  async (programStudentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/program_student/${programStudentID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const saveProgramStudentPayment = createAsyncThunk(
  'saveProgramStudentPayment',
  async (payment, thunkAPI) => {
    const saveUrl = payment.id ? `/api/program_student_payment/${payment.id}/` : `/api/program_student_payment/`
    const saveMethod = payment.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(payment)
    })
    const data = await response.json()
    return data
  }
)


export const deleteProgramStudentPayment = createAsyncThunk(
  'deleteProgramStudentPayment',
  async (payment, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/program_student_payment/${payment.id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })

    const data = await response.json()
    return data
  }
)


export const fetchProgramPayments = createAsyncThunk(
  'fetchProgramPayments',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/program_payment/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveProgramPayment = createAsyncThunk(
  'saveProgramPayment',
  async (payment, thunkAPI) => {
    const saveUrl = payment.id ? `/api/program_payment/${payment.id}/` : `/api/program_payment/`
    const saveMethod = payment.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(payment)
    })
    const data = await response.json()
    return data
  }
)


export const deleteProgramPayment = createAsyncThunk(
  'deleteProgramPayment',
  async (programPaymentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/program_payment/${programPaymentID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchChapterSkills = createAsyncThunk(
  'fetchChapterSkills',
  async (chapterID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/skill/?chapter=${chapterID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchBookSkills = createAsyncThunk(
  'fetchBookSkills',
  async (bookID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/skill/?book=${bookID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchAllSkills = createAsyncThunk(
  'fetchAllSkills',
  async (programID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/skill/?program=${programID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveSkill = createAsyncThunk(
  'saveSkill',
  async (skill, thunkAPI) => {
    const saveUrl = skill.id ? `/api/skill/${skill.id}/` : `/api/skill/`
    const saveMethod = skill.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(skill)
    })
    const data = await response.json()
    return data
  }
)


export const deleteSkill = createAsyncThunk(
  'deleteSkill',
  async (skillID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/skill/${skillID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



/// Student Assessment APIs
export const fetchStudentAssessments = createAsyncThunk(
  'fetchStudentAssessments',
  async ({studentID}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    let query = studentID ? `?student=${studentID}` : ''

    const response = await fetch(`/api/student_assessment/${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentReadingData = createAsyncThunk(
  'fetchStudentReadingData',
  async ({studentID}, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/program_student/${studentID}/fetch_student_reading_data/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentVideoData = createAsyncThunk(
  'fetchStudentVideoData',
  async ({studentID}, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/program_student/${studentID}/fetch_student_video_data/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const fetchHESIReports = createAsyncThunk(
  'fetchHESIReports',
  async (studentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/hesi_report/${studentID}/get_reports/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveHESIReport = createAsyncThunk(
  'saveHESIReport',
  async (HESIReport, thunkAPI) => {
    const saveUrl = HESIReport.id ? `/api/hesi_report/${HESIReport.id}/` : `/api/hesi_report/`
    const saveMethod = HESIReport.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token

    const formData = new FormData()
    formData.append('name', HESIReport.name)
    formData.append('score', HESIReport.score)
    formData.append('student', HESIReport.student)

    if(HESIReport.hesi_report_file){
        formData.append('report', HESIReport.hesi_report_file)
    }

    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)



export const deleteHESIReport = createAsyncThunk(
  'deleteHESIReport',
  async (reportID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/hesi_report/${reportID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)





///

const programSlice = createSlice({
  name: 'program',

  initialState: {
    programs: [],
    modules: [],

    cohorts: [],
    books: [],
    chapters: [],
    questions: [],
    skills: [],
    allSkills: [],
    assessments: [],
    videos: [],
    lessons: [],
    courses: [],

    programStudents: [],
    studentCount: 0,
    studentPage: 1,

    programPayments: [],
    programChapters: [],
    studentGrades: [],
    studentPractices: [],

    lectures: [],
    clinicals: [],
    moduleSkills: [],
    clinicalEvals: []
  },

  reducers: {
    setStudentPage(state, action) {
      state.studentPage = action.payload
    },
  },

  extraReducers: (builder) => {
     builder
        .addCase(uploadStudentDocument.fulfilled, (state, action) => {
            const documentRequestID = action.meta.arg.documentRequestID

            state.programStudents = state.programStudents.map(pStudent => {
                if( pStudent.student.document_requests.length > 0 ){
                      let docRequests = pStudent.student.document_requests.map( dReq => {
                         if( dReq.id == documentRequestID ){
                            return action.payload
                         }
                         return dReq
                      })

                      let student = Object.assign({}, pStudent.student, {document_requests: docRequests})
                      return Object.assign({}, pStudent, {student})
                }

                return pStudent;
            })
        })

        .addCase(fetchClinicalEvals.fulfilled, (state, action) => {
          state.clinicalEvals = action.payload.results
        })

        .addCase(saveClinicalEval.fulfilled, (state, action) => {
          const savedClinicalEval = action.meta.arg
          if(savedClinicalEval.id){
             state.clinicalEvals = state.clinicalEvals.map(clinicalEval => {
                if( clinicalEval.id == savedClinicalEval.id ){
                    return action.payload
                }
                return clinicalEval
             })
          }else{
             state.clinicalEvals.push(action.payload)
          }
        })

        .addCase(deleteClinicalEval.pending, (state, action) => {
           state.clinicalEvals = state.clinicalEvals.filter( (clinicalEval) => clinicalEval.id != action.meta.arg )
        })


        .addCase(saveDocumentRequest.fulfilled, (state, action) => {
            const savedDocumentRequest = action.meta.arg
            console.log('Saved Doc: ', savedDocumentRequest)

            state.programStudents = state.programStudents.map( (pStudent) => {
                //console.log(pStudent.student.id)
                //console.log(`${pStudent.student.id} == ${savedDocumentRequest.user}`)

                if( pStudent.student.id == savedDocumentRequest.user ){
                    console.log('Student Found')
                    const student = pStudent.student
                    let documentRequests = student.document_requests

                    if( savedDocumentRequest.id ){
                        documentRequests = documentRequests.map( docReq => {
                            if( docReq.id == savedDocumentRequest.id ){
                                return action.payload
                            }

                            return docReq
                        })

                    }else{
                        documentRequests.push(action.payload)
                    }

                    let updatedStudent = Object.assign({}, student, {document_requests: documentRequests})
                    return Object.assign({}, pStudent, { student: updatedStudent })
                }
                return pStudent
            })
        })

        .addCase(fetchChapterSkills.fulfilled, (state, action) => {
          state.skills = action.payload
        })

        .addCase(fetchBookSkills.fulfilled, (state, action) => {
          state.skills = action.payload
        })

        .addCase(fetchAllSkills.fulfilled, (state, action) => {
          state.allSkills = action.payload
        })

        .addCase(saveSkill.fulfilled, (state, action) => {
          const savedSkill = action.meta.arg
          if(savedSkill.id){
             state.skills = state.skills.map(skill => {
                if( skill.id == savedSkill.id ){
                    return action.payload
                }
                return skill
             })
          }else{
             state.skills.push(action.payload)
          }
        })

        .addCase(deleteSkill.pending, (state, action) => {
           state.skills = state.skills.filter( (skill) => skill.id != action.meta.arg )
        })

        .addCase(fetchPrograms.fulfilled, (state, action) => {
          state.programs = action.payload.results
        })

        .addCase(fetchModules.fulfilled, (state, action) => {
          state.modules = action.payload.results
        })

        .addCase(fetchModuleSkills.fulfilled, (state, action) => {
          state.moduleSkills = action.payload
        })

        .addCase(saveModuleSkills.fulfilled, (state, action) => {
          state.moduleSkills = action.payload
        })

        .addCase(saveModule.fulfilled, (state, action) => {
          const savedModule = action.meta.arg
          if(savedModule.id){
             state.modules = state.modules.map(module => {
                if( module.id == savedModule.id ){
                    return action.payload
                }
                return module
             })
          }else{
             state.modules.push(action.payload)
          }
        })

        .addCase(fetchLectures.fulfilled, (state, action) => {
          state.lectures = action.payload.results
        })

        .addCase(saveLecture.fulfilled, (state, action) => {
          const savedLecture = action.meta.arg
          if(savedLecture.id){
             state.lectures = state.lectures.map(lecture => {
                if( lecture.id == savedLecture.id ){
                    return action.payload
                }
                return lecture
             })
          }else{
             state.lectures.push(action.payload)
          }
        })

        .addCase(deleteLecture.pending, (state, action) => {
           state.lectures = state.lectures.filter( (lecture) => lecture.id != action.meta.arg )
        })

        .addCase(saveProgram.fulfilled, (state, action) => {
          const savedProgram = action.meta.arg
          if(savedProgram.id){
             state.programs = state.programs.map((program) => {
                if( program.id == savedProgram.id ){
                    return action.payload
                }
                return program
             })
          }else{
             state.programs.push(action.payload)
          }
        })

        .addCase(deleteProgram.pending, (state, action) => {
           state.programs = state.programs.filter( (program) => program.id != action.meta.arg )
        })


        .addCase(addStudentCohort.fulfilled, (state, action) => {
            let {programStudent, cohortID} = action.meta.arg
            const updatedStudents = state.programStudents.map(( pStudent ) => {
                if( pStudent.id == programStudent.id ){
                    let updatedStudent = Object.assign({}, pStudent, {})
                    updatedStudent.cohorts.push(cohortID)
                    updatedStudent.payments = [...updatedStudent.payments, ...action.payload]
                    return updatedStudent
                }
                return pStudent
            })

            state.programStudents = updatedStudents
        })


        .addCase(updateStudentCohort.fulfilled, (state, action) => {
            let {programStudent, cohortID, newCohortID} = action.meta.arg
            const updatedStudents = state.programStudents.map(( pStudent ) => {
                if( pStudent.id == programStudent.id ){
                    let updatedStudent = Object.assign({}, pStudent, {})
                    updatedStudent.cohorts = updatedStudent.cohorts.filter( cID => cID != cohortID )
                    updatedStudent.cohorts.push(newCohortID)
                    return updatedStudent
                }
                return pStudent
            })
            state.programStudents = updatedStudents
        })


        .addCase(removeStudentCohort.fulfilled, (state, action) => {
            let {programStudent, cohortID} = action.meta.arg
            const updatedStudents = state.programStudents.map(( pStudent ) => {
                if( pStudent.id == programStudent.id ){
                    let updatedStudent = Object.assign({}, pStudent, {})
                    updatedStudent.cohorts = pStudent.cohorts.filter( cID => cID != cohortID)
                    return updatedStudent
                }
                return pStudent
            })

            state.programStudents = updatedStudents
        })

        .addCase(fetchCohorts.fulfilled, (state, action) => {
          state.cohorts = action.payload
        })


        .addCase(saveCohort.fulfilled, (state, action) => {
          const savedCohort = action.meta.arg
          if(savedCohort.id){
             state.cohorts = state.cohorts.map((cohort) => {
                if( cohort.id == savedCohort.id ){
                    return action.payload
                }
                return cohort
             })
          }else{
             state.cohorts.push(action.payload)
          }
        })

        .addCase(deleteCohort.pending, (state, action) => {
           state.cohorts = state.cohorts.filter( (cohort) => cohort.id != action.meta.arg )
        })

        .addCase(fetchBooks.fulfilled, (state, action) => {
          state.books = action.payload.results
        })

        .addCase(saveBook.fulfilled, (state, action) => {
          const savedBook = action.meta.arg
          if(savedBook.id){
             state.books = state.books.map((book) => {
                if( book.id == savedBook.id ){
                    return action.payload
                }
                return book
             })
          }else{
             state.books.push(action.payload)
          }
        })

        .addCase(deleteBook.pending, (state, action) => {
           state.books = state.books.filter( (book) => book.id != action.meta.arg )
        })

        .addCase(fetchCourses.fulfilled, (state, action) => {
          state.courses = action.payload.results
        })

        .addCase(saveCourse.fulfilled, (state, action) => {
          const savedCourse = action.meta.arg
          if(savedCourse.id){
             state.courses = state.courses.map((course) => {
                if( course.id == savedCourse.id ){
                    return action.payload
                }
                return course
             })
          }else{
             state.courses.push(action.payload)
          }
        })

        .addCase(deleteCourse.pending, (state, action) => {
           state.courses = state.courses.filter( (course) => course.id != action.meta.arg )
        })

        .addCase(fetchChapters.fulfilled, (state, action) => {
          state.chapters = action.payload
        })

        .addCase(fetchProgramChapters.fulfilled, (state, action) => {
          state.programChapters = action.payload.results
        })

        .addCase(saveChapter.fulfilled, (state, action) => {
          const savedChapter = action.meta.arg
          if(savedChapter.id){
             state.chapters = state.chapters.map((chapter) => {
                if( chapter.id == savedChapter.id ){
                    return action.payload
                }
                return chapter
             })
          }else{
             state.chapters.push(action.payload)
          }
        })

        .addCase(deleteChapter.pending, (state, action) => {
           state.chapters = state.chapters.filter( (chapter) => chapter.id != action.meta.arg )
        })


        .addCase(fetchQuestions.fulfilled, (state, action) => {
          state.questions = action.payload.results
        })

        .addCase(saveQuestion.fulfilled, (state, action) => {
          const savedQuestion = action.meta.arg
          if(savedQuestion.id){
             state.questions = state.questions.map((question) => {
                if( question.id == savedQuestion.id ){
                    return action.payload
                }
                return question
             })
          }else{
             state.questions.push(action.payload)
          }
        })

        .addCase(deleteQuestion.pending, (state, action) => {
           state.questions = state.questions.filter( (question) => question.id != action.meta.arg )
        })


        .addCase(fetchAssessments.fulfilled, (state, action) => {
          state.assessments = action.payload
        })

        .addCase(saveAssessment.fulfilled, (state, action) => {
          const savedAssessment = action.meta.arg
          if(savedAssessment.id){
             state.assessments = state.assessments.map((assessment) => {
                if( assessment.id == savedAssessment.id ){
                    return action.payload
                }
                return assessment
             })
          }else{
             state.assessments.push(action.payload)
          }
        })

        .addCase(deleteAssessment.pending, (state, action) => {
           state.assessments = state.assessments.filter( (assessment) => assessment.id != action.meta.arg )
        })

        .addCase(fetchVideos.fulfilled, (state, action) => {
          state.videos = action.payload.results
        })

        .addCase(saveVideo.fulfilled, (state, action) => {
          const savedVideo = action.meta.arg
          if(savedVideo.id){
             state.videos = state.videos.map((video) => {
                if( video.id == savedVideo.id ){
                    return action.payload
                }
                return video
             })
          }else{
             state.videos.push(action.payload)
          }
        })

        .addCase(deleteVideo.pending, (state, action) => {
           state.videos = state.videos.filter( (video) => video.id != action.meta.arg )
        })

        .addCase(fetchLessons.fulfilled, (state, action) => {
          state.lessons = action.payload
        })

        .addCase(saveLesson.fulfilled, (state, action) => {
          const savedLesson = action.meta.arg
          if(savedLesson.id){
             state.lessons = state.lessons.map((lesson) => {
                if( lesson.id == savedLesson.id ){
                    return action.payload
                }
                return lesson
             })
          }else{
             state.lessons.push(action.payload)
          }
        })

        .addCase(deleteLesson.pending, (state, action) => {
           state.lessons = state.lessons.filter( (lesson) => lesson.id != action.meta.arg )
        })

        .addCase(fetchProgramStudents.fulfilled, (state, action) => {
          state.programStudents = action.payload.results
          state.studentCount = action.payload.count
        })

        .addCase(fetchStudentGrades.fulfilled, (state, action) => {
          state.studentGrades = action.payload
        })

        .addCase(fetchStudentPractices.fulfilled, (state, action) => {
          state.studentPractices = action.payload
        })

        .addCase(saveProgramStudent.fulfilled, (state, action) => {
          const savedProgramStudent = action.meta.arg
          if(savedProgramStudent.id){
             state.programStudents = state.programStudents.map((pStudent) => {
                if( pStudent.id == savedProgramStudent.id ){
                    return action.payload
                }
                return pStudent
             })
          }else{
             state.programStudents = [ action.payload, ...state.programStudents ]
          }
        })

        .addCase(deleteProgramStudent.pending, (state, action) => {
           state.programStudents = state.programStudents.filter( (pStudent) => pStudent.id != action.meta.arg )
        })

        //
        .addCase(saveProgramStudentPayment.fulfilled, (state, action) => {
              const payment = action.meta.arg
              state.programStudents = state.programStudents.map((pStudent) => {
                if( pStudent.id == payment.program_student ){
                    if(payment.id){
                        console.log('Update Existing')
                        let payments = pStudent.payments.map((pItem) => {
                            if( pItem.id == payment.id ){
                                return action.payload
                            }
                            return pItem
                        })
                        return Object.assign({}, pStudent, {payments})
                    }else{
                        console.log('Add New Item')
                        let payments = [...pStudent.payments, action.payload]
                        return Object.assign({}, pStudent, {payments})
                    }

                    let payments = [...pStudent.payments, payment]
                    return pStudent
                }
                return pStudent
             })
        })

        .addCase(deleteProgramStudentPayment.pending, (state, action) => {
            const payment = action.meta.arg
            state.programStudents = state.programStudents.map((pStudent) => {
                if( pStudent.id == payment.program_student ){
                    let payments = pStudent.payments.filter( p => p.id != payment.id)
                    return Object.assign({}, pStudent, {payments})
                }
                return pStudent
            })
        })

        .addCase(fetchProgramPayments.fulfilled, (state, action) => {
           state.programPayments = action.payload.results
        })

        .addCase(saveProgramPayment.fulfilled, (state, action) => {
          const savedPayment = action.meta.arg
          if(savedPayment.id){
             state.programPayments = state.programPayments.map((payment) => {
                if( payment.id == savedPayment.id ){
                    return action.payload
                }
                return payment
             })
          }else{
             state.programPayments.push(action.payload)
          }
        })

        .addCase(deleteProgramPayment.pending, (state, action) => {
           state.programPayments = state.programPayments.filter( (payment) => payment.id != action.meta.arg )
        })


  }

})

export const { setStudentPage } = programSlice.actions
export default programSlice.reducer