import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const NCLEXCategoryDashboard = loadable(() => import("./NCLEXCategoryDashboard.js"))
const NCLEXCategoryDetailDashboard = loadable(() => import("./NCLEXCategoryDetailDashboard.js"))
const NCLEXCategoryExamDashboard = loadable(() => import("./NCLEXCategoryExamDashboard.js"))


export default [
    <Route exact path="/admin/nclex/" element={<NCLEXCategoryDashboard/>} />,
    <Route exact path="/admin/nclex/:categoryID/" element={<NCLEXCategoryDetailDashboard/>} />,
    <Route exact path="/admin/nclex/:categoryID/:subcategoryID/" element={<NCLEXCategoryExamDashboard/>} />
]