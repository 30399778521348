import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const ProspectDashboard = loadable(() => import("./ProspectDashboard.js"))


export default [
    <Route exact path="/admin/prospect/" element={<ProspectDashboard/>} />,
]