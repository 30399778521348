import { combineReducers } from 'redux'
import user from '../components/auth_pages/_slice'
import landing from '../components/landing_pages/reducers'
import admin from '../components/admin_dashboard/reducers'
import student from '../components/student_dashboard/reducers'

export default combineReducers({
  user,
  landing,
  admin,
  student
})