import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'



export const fetchDocumentRequests = createAsyncThunk(
  'fetchDocumentRequests',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_document_requests/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchLectures = createAsyncThunk(
  'fetchLectures',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_lectures/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchSkills = createAsyncThunk(
  'fetchSkills',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_skills/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const uploadDocument = createAsyncThunk(
  'uploadDocument',
  async ({documentRequestID, file}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const formData = new FormData()
    formData.append('uploadFile', file)
    formData.append('documentRequestID', documentRequestID)

    const response = await fetch(`/api/student/upload_document/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)


export const saveVideoCapture = createAsyncThunk(
  'saveVideoCapture',
  async ({ studentAssessmentID, videoBlob, videoFormat }, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const formData = new FormData()
    formData.append('studentAssessmentID', studentAssessmentID)
    formData.append('videoBlob', videoBlob)
    formData.append('videoFormat', videoFormat)

    const response = await fetch(`/api/student/save_video_capture/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)

export const saveScreenCapture = createAsyncThunk(
  'saveScreenCapture',
  async ({ studentAssessmentID, videoBlob, videoFormat }, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const formData = new FormData()
    formData.append('studentAssessmentID', studentAssessmentID)
    formData.append('videoBlob', videoBlob)
    formData.append('videoFormat', videoFormat)

    const response = await fetch(`/api/student/save_screen_capture/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`
        },
        body: formData
    })
    const data = await response.json()
    return data
  }
)


export const fetchModules = createAsyncThunk(
  'fetchModules',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_modules/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchCohorts = createAsyncThunk(
  'fetchCohorts',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_cohorts/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchLessons = createAsyncThunk(
  'fetchLessons',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_lessons/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchBooks = createAsyncThunk(
  'fetchBooks',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_books/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchAssessment = createAsyncThunk(
  'fetchAssessment',
  async (assessmentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/${assessmentID}/fetch_assessment/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const retakeAssessment = createAsyncThunk(
  'retakeAssessment',
  async (assessmentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/${assessmentID}/retake_assessment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const updateStudentAssessment = createAsyncThunk(
  'updateStudentAssessment',
  async (studentAssessment, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/${studentAssessment.id}/update_student_assessment/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(studentAssessment)
    })
    const data = await response.json()
    return data
  }
)

export const fetchVideos = createAsyncThunk(
  'fetchVideos',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_videos/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)

export const fetchStudentAssessments = createAsyncThunk(
  'fetchStudentAssessments',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_student_assessments/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentChapterPractices = createAsyncThunk(
  'fetchStudentChapterPractices',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_student_chapter_practices/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentChapterPractice = createAsyncThunk(
  'fetchStudentChapterPractice',
  async (chapterID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/${chapterID}/fetch_student_chapter_practice/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const updateStudentChapterPractice = createAsyncThunk(
  'updateStudentChapterPractice',
  async ({practiceID, questions}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/${practiceID}/update_student_chapter_practice/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({questions})
    })
    const data = await response.json()
    return data
  }
)



export const fetchPayments = createAsyncThunk(
  'fetchPayments',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_payments/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)




export const paymentCompleted = createAsyncThunk(
  'paymentCompleted',
  async (paymentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/payment_completed/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
           paymentID: paymentID
        })
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentAccessLevel = createAsyncThunk(
  'fetchStudentAccessLevel',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/fetch_access_level/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const updateChapterReadTime = createAsyncThunk(
  'updateChapterReadTime',
  async ({bookID, chapterID, timeSpent}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/update_chapter_read_time/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({bookID, chapterID, timeSpent})
    })
    const data = await response.json()
    return data
  }
)


export const updateVideoWatchTime = createAsyncThunk(
  'updateVideoWatchTime',
  async ({videoID, timeSpent}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/student/update_video_watch_time/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({videoID, timeSpent})
    })
    const data = await response.json()
    return data
  }
)



const lessonSlice = createSlice({
  name: 'lesson',

  initialState: {
    modules: [],
    activeModule: null,

    cohorts: [],

    books: [],
    lessons: [],
    assessment: {},
    studentAssessments: [],
    studentChapterPractices: [],
    studentChapterPractice: {},
    payments: [],
    videos: [],

    studentAccessLevel: 'no_access',

    documentRequests: [],
    lectures: [],
    skills: []
  },

  reducers: {
    setActiveModule(state, action) {
      state.activeModule = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
        .addCase(fetchBooks.fulfilled, (state, action) => {
          state.books = action.payload
        })

        .addCase(fetchDocumentRequests.fulfilled, (state, action) => {
          state.documentRequests = action.payload
        })

        .addCase(uploadDocument.fulfilled, (state, action) => {
          let updatedDocReq = action.payload

          state.documentRequests = state.documentRequests.map( docReq => {
            if( docReq.id == updatedDocReq.id ){
                return updatedDocReq
            }
            return docReq
          })
        })

        .addCase(fetchLectures.fulfilled, (state, action) => {
          state.lectures = action.payload
        })

        .addCase(fetchSkills.fulfilled, (state, action) => {
          state.skills = action.payload
        })

        .addCase(fetchModules.fulfilled, (state, action) => {
          let modules = action.payload
          state.modules = modules
          state.activeModule = modules[ modules.length - 1 ]
        })

        .addCase(fetchCohorts.fulfilled, (state, action) => {
          state.cohorts = action.payload
        })

        .addCase(fetchLessons.fulfilled, (state, action) => {
          state.lessons = action.payload
        })

        .addCase(fetchAssessment.fulfilled, (state, action) => {
          state.assessment = action.payload
        })

        .addCase(updateStudentAssessment.fulfilled, (state, action) => {
          state.assessment = action.payload
        })

        .addCase(fetchStudentAssessments.fulfilled, (state, action) => {
            state.studentAssessments = action.payload
        })

        .addCase(fetchVideos.fulfilled, (state, action) => {
            state.videos = action.payload
        })

        .addCase(fetchStudentChapterPractices.fulfilled, (state, action) => {
            state.studentChapterPractices = action.payload
        })

        .addCase(fetchStudentChapterPractice.fulfilled, (state, action) => {
            state.studentChapterPractice = action.payload
        })

        .addCase(updateStudentChapterPractice.pending, (state, action) => {
            state.studentChapterPractice = Object.assign({}, state.studentChapterPractice, {questions: action.meta.arg.questions})
        })

        .addCase(fetchPayments.fulfilled, (state, action) => {
          state.payments = action.payload
        })

        .addCase(fetchStudentAccessLevel.fulfilled, (state, action) => {
           state.studentAccessLevel = action.payload
        })

        .addCase(paymentCompleted.pending, (state, action) => {
           state.payments = state.payments.map( (payment) => {
                if( payment.id == action.meta.arg){
                    return Object.assign({}, payment, {
                        is_paid: true,
                        date_paid: (new Date()).toISOString()
                    })
                }
                return payment
           } )
        })

  }

})

export const { setActiveModule } = lessonSlice.actions
export default lessonSlice.reducer