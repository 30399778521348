import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const trackPageView = createAsyncThunk(
  'trackPageView',
  async ({}, thunkAPI) => {

     const response = await fetch('/api/visitor/track_page_view/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            path: window.location.pathname,
        })
     })
     const data = await response.json()
     return data
  }
)


export const sectionViewed = createAsyncThunk(
  'sectionViewed',
  async ({sectionID, variantID}, thunkAPI) => {
    const response = await fetch(`/api/visitor/section_viewed/`,
            {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({sectionID, variantID})
        }
    )
    const data = await response.json()
    return data
  }
)

export const sectionClicked = createAsyncThunk(
  'sectionClicked',
  async ({sectionID, variantID}, thunkAPI) => {
    const response = await fetch(`/api/visitor/section_clicked/`,
            {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({sectionID, variantID})
        }
    )
    const data = await response.json()
    return data
  }
)

export const leadGenSubmit = async ({firstName, lastName, email, phone}) => {
    console.log('Lead Gen Submit')
    const response = await fetch('/api/visitor/lead_gen_submit/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            pageID: window.pageData.page.id,
            versionID: window.pageData.page.active_version
        })
    });

    const data = await response.json()
    return data
}

createAsyncThunk(
  'trackPageView',
  async ({firstName, lastName, email, phone}, thunkAPI) => {

     console.log('Lead Gen Submit')
     const response = await fetch('/api/visitor/lead_gen_submit/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            pageID: window.pageData.page.id,
            versionID: window.pageData.page.active_version
        })
     });

     const data = await response.json()
     return data
  }

)


const visitorSlice = createSlice({
  name: 'visitor',
  initialState: {},
  reducers: {},
})

export default visitorSlice.reducer