import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const ProgramDashboard = loadable(() => import("./ProgramDashboard.js"));
const ProgramDetailDashboard = loadable(() => import("./ProgramDetailDashboard.js"));

const ModuleDetailDashboard = loadable(() => import("./ModuleDetailDashboard.js"));
const CohortDetailDashboard = loadable(() => import("./CohortDetailDashboard.js"));

const BookDetailDashboard = loadable(() => import("./BookDetailDashboard.js"));

const ChapterDetailDashboard = loadable(() => import("./ChapterDetailDashboard.js"));

const LessonDetailDashboard = loadable(() => import("./LessonDetailDashboard.js"));




export default [
    <Route exact path="/admin/program/" element={<ProgramDashboard/>} />,
    <Route exact path="/admin/program/:programID/" element={<ProgramDetailDashboard/>} />,

    <Route exact path="/admin/program/:programID/module/:moduleID/" element={<ModuleDetailDashboard/>} />,
    <Route exact path="/admin/program/:programID/cohort/:cohortID/" element={<CohortDetailDashboard/>} />,

    <Route exact path="/admin/program/:programID/book/:bookID/" element={<BookDetailDashboard/>} />,

    <Route exact path="/admin/program/:programID/book/:bookID/chapter/:chapterID/" element={<ChapterDetailDashboard/>} />,

    <Route exact path="/admin/program/:programID/lesson/:lessonID/" element={<LessonDetailDashboard/>} />,
]