import { createSlice } from '@reduxjs/toolkit'


const userSlice = createSlice({
  name: 'user',

  initialState: {
    token: '',
    currentUser: null
  },

  reducers: {

    login(state, action) {
      state.token = action.payload.token
      state.currentUser = action.payload.user
    },

    logout(state, action) {
      state.token = ''
      state.currentUser = null
      localStorage.clear()
    },

  },

})


export const { login, logout } = userSlice.actions
export default userSlice.reducer