// React
import React from 'react';

// Styles
import "./components/admin_dashboard/styles/dashboard.scss"

// React Redux
import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit'
import {thunk} from "redux-thunk";

import { createLogger } from 'redux-logger'

// React Router
import {Routes, BrowserRouter} from 'react-router-dom';

// Routes
import AuthPageRoutes from "./components/auth_pages/_routes";
import LandingPageRoutes from "./components/landing_pages/_routes";
import AdminDashboardRoutes from "./components/admin_dashboard/_routes";
import StudentDashboardRoutes from "./components/student_dashboard/_routes";

// Reducer
import rootReducer from './reducers'

// React Persist
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'


// Create Persisted Reducer
const persistConfig = {
  key: 'root-state-1',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


// Create Persisted Store
const logger = createLogger();

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [thunk, logger],
})

const persistor = persistStore(store)


// Setup RootContainer
class RootContainer extends React.Component {

    render() {
        let routes = []
        routes.push(AuthPageRoutes)
        routes.push(LandingPageRoutes)
        routes.push(AdminDashboardRoutes)
        routes.push(StudentDashboardRoutes)

        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Routes>
                            {routes}
                        </Routes>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        );
    }

}


// Export App Component
export default class App extends React.Component {
    render() {
        return (
            <RootContainer />
        )
    }
}