import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';

// Routes
import CompanyRoutes from "./packages/company/_routes";
import DocumentRoutes from "./packages/document/_routes";
import PageRoutes from "./packages/page/_routes";
import ProspectRoutes from "./packages/prospect/_routes";
import ProgramRoutes from "./packages/program/_routes";
import NCLEXRoutes from "./packages/nclex/_routes";
import RangeRoutes from "./packages/range/_routes";


// Views
const HomeDashboard = loadable(() => import("./HomeDashboard.js"));


export default [
    <Route exact path="/admin/" element={<HomeDashboard/>} />,
    ...CompanyRoutes,
    ...DocumentRoutes,
    ...PageRoutes,
    ...ProspectRoutes,
    ...ProgramRoutes,
    ...NCLEXRoutes,
    ...RangeRoutes
]