import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const fetchPages = createAsyncThunk(
  'fetchPages',
  async (args, thunkAPI) => {
    const response = await fetch(`/api/page/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const savePage = createAsyncThunk(
  'savePage',
  async (page, thunkAPI) => {
    const saveUrl = page.id ? `/api/page/${page.id}/` : `/api/page/`
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: page.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(page)
    })
    const data = await response.json()
    return data
  }
)

export const deletePage = createAsyncThunk(
  'deletePage',
  async (pageID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/page/${pageID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchSections = createAsyncThunk(
  'fetchSections',
  async (versionID, thunkAPI) => {
    const response = await fetch(`/api/page-version/${versionID}/sections/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveSection = createAsyncThunk(
  'saveSection',
  async (section, thunkAPI) => {
    const saveUrl = section.id ? `/api/section/${section.id}/` : `/api/section/`
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: section.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(section)
    })
    const data = await response.json()
    return data
  }
)


export const saveVariant = createAsyncThunk(
  'saveVariant',
  async ({section, variant}, thunkAPI) => {
    const saveUrl = variant.id ? `/api/variant/${variant.id}/` : `/api/variant/`
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: variant.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(variant)
    })
    const data = await response.json()

    return data
  }
)

export const deleteVariant = createAsyncThunk(
  'deleteVariant',
  async ({section, variant}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/variant/${variant.id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)

export const deleteSection = createAsyncThunk(
  'deleteSection',
  async (sectionID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/section/${sectionID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)

export const fetchVersions = createAsyncThunk(
  'fetchVersions',
  async (pageID, thunkAPI) => {
    const response = await fetch(`/api/page/${pageID}/versions/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveVersion = createAsyncThunk(
  'saveVersion',
  async (version, thunkAPI) => {
    const saveUrl = version.id ? `/api/page-version/${version.id}/` : `/api/page-version/`
    const response = await fetch(saveUrl, {
        method: version.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
        body: JSON.stringify(version)
    })
    const data = await response.json()
    return data
  }
)


export const setPageActiveVersion = createAsyncThunk(
  'setPageActiveVersion',
  async ({pageID, versionID}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/page/${pageID}/set_active_version/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({versionID})
    })
    const data = await response.json()
    return data
  }
)

export const updateSectionOrdering = createAsyncThunk(
  'updateSectionOrdering',
  async (orderList, thunkAPI) => {
    const response = await fetch( '/api/page/update_section_ordering/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
        body: JSON.stringify(orderList)
    })
    const data = await response.json()
    return data
  }
)


export const updatePageCache = createAsyncThunk(
  'updatePageCache',
  async (pageID, thunkAPI) => {
    const response = await fetch( `/api/page/${pageID}/update_cache/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        }
    })
    const data = await response.json()
    return data
  }
)


const pageSlice = createSlice({
  name: 'page',

  initialState: {
    pages: [],
    sections: [],
    versions: [],
  },

  reducers: {

  },

  extraReducers: (builder) => {
    builder

        .addCase(fetchPages.fulfilled, (state, action) => {
           state.pages = action.payload
        })

        .addCase(savePage.fulfilled, (state, action) => {
          const savedPage = action.meta.arg
          if(savedPage.id){
             state.pages = state.pages.map((page) => {
                if( page.id == savedPage.id ){
                    return action.payload
                }
                return page
             })
          }else{
             state.pages = [...state.pages, action.payload]
          }
        })

        .addCase(deletePage.pending, (state, action) => {
          state.pages = state.pages.filter( (page) => page.id != action.meta.arg )
        })

        .addCase(fetchSections.fulfilled, (state, action) => {
            state.sections = action.payload
        })

        .addCase(saveSection.fulfilled, (state, action) => {
          const savedSection = action.meta.arg
          if(savedSection.id){
             state.sections = state.sections.map((section) => {
                if( section.id == savedSection.id ){
                    return action.payload
                }
                return section
             })
          }else{
             state.sections = [...state.sections, action.payload]
          }
        })

        .addCase(saveVariant.fulfilled, (state, action) => {
          const {section, variant} = action.meta.arg
          state.sections = state.sections.map((s) => {
              if( s.id == section.id ){
                  if(variant.id){
                      s.variants = s.variants.map((v)=>{
                          if( v.id == variant.id ){
                              return variant
                          }
                          return v
                      })
                  }else{
                    s.variants = [action.payload, ...s.variants]
                  }
              }
              return s
           })
        })

        .addCase(deleteVariant.pending, (state, action) => {
          const {section, variant} = action.meta.arg
          state.sections = state.sections.map((s) => {
              if( s.id == section.id ){
                  s.variants = s.variants.filter(v=>v.id!=variant.id)
              }
              return s
           })
        })

        .addCase(deleteSection.pending, (state, action) => {
          state.sections = state.sections.filter( (section) => section.id != action.meta.arg )
        })

        .addCase(fetchVersions.fulfilled, (state, action) => {
            state.versions = action.payload
        })

        .addCase(saveVersion.fulfilled, (state, action) => {
          const savedVersion = action.meta.arg
          if(savedVersion.id){
             state.versions = state.versions.map((version) => {
                if( version.id == savedVersion.id ){
                    return action.payload
                }
                return version
             })
          }else{
             state.versions = [action.payload, ...state.versions]
          }
        })

        .addCase(setPageActiveVersion.fulfilled, (state, action) => {
          const {pageID, versionID} = action.meta.arg
          state.pages = state.pages.map((p) => {
            if(p.id == pageID){
                return Object.assign({}, p, {active_version: versionID})
            }

            return p
          })
        })

        .addCase(updateSectionOrdering.pending, (state, action) => {
            const orderList = action.meta.arg

            let orderMap = {}
            for( let i = 0; i < orderList.length; i++ ){
                let orderItem = orderList[i]
                orderMap[orderItem['id']] = orderItem['order']
            }

            state.sections = state.sections.map((s) => {
                return Object.assign({}, s, {order: orderMap[s.id]})
            })
        })

  }

})

export default pageSlice.reducer