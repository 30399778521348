import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const fetchCompanies = createAsyncThunk(
  'fetchCompanies',
  async ({}, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/company/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })

    const data = await response.json()
    return data
  }
)


export const saveCompany = createAsyncThunk(
  'saveCompany',
  async (company, thunkAPI) => {
    const saveUrl = company.id ? `/api/company/${company.id}/` : `/api/company/`
    const saveMethod = company.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(company)
    })
    const data = await response.json()
    return data
  }
)


export const deleteCompany = createAsyncThunk(
  'deleteCompany',
  async (companyID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/company/${companyID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchInvoices = createAsyncThunk(
  'fetchInvoices',
  async ({companyID}, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/company_invoice/?company=${companyID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })

    const data = await response.json()
    return data
  }
)


export const saveInvoice = createAsyncThunk(
  'saveInvoice',
  async (invoice, thunkAPI) => {
    const saveUrl = invoice.id ? `/api/company_invoice/${invoice.id}/` : `/api/company_invoice/`
    const saveMethod = invoice.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(invoice)
    })
    const data = await response.json()
    return data
  }
)


export const deleteInvoice = createAsyncThunk(
  'deleteInvoice',
  async (invoiceID, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/company_invoice/${invoiceID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


export const fetchStudentUsers = createAsyncThunk(
  'fetchStudentUsers',
  async ({}, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/company/fetch_student_users/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const saveInvoiceItem = createAsyncThunk(
  'saveInvoiceItem',
  async (invoiceItem, thunkAPI) => {
    const saveUrl = invoiceItem.id ? `/api/company_invoice_item/${invoiceItem.id}/` : `/api/company_invoice_item/`
    const saveMethod = invoiceItem.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(invoiceItem)
    })
    const data = await response.json()
    return data
  }
)


export const deleteInvoiceItem = createAsyncThunk(
  'deleteInvoiceItem',
  async (invoiceItem, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/company_invoice_item/${invoiceItem.id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)




export const savePaymentItem = createAsyncThunk(
  'savePaymentItem',
  async (paymentItem, thunkAPI) => {
    const saveUrl = paymentItem.id ? `/api/company_invoice_payment/${paymentItem.id}/` : `/api/company_invoice_payment/`
    const saveMethod = paymentItem.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(paymentItem)
    })
    const data = await response.json()
    return data
  }
)


export const deletePaymentItem = createAsyncThunk(
  'deletePaymentItem',
  async (paymentItem, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/company_invoice_payment/${paymentItem.id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



const companySlice = createSlice({
  name: 'company',

  initialState: {
    companies: [],
    invoices: [],
    studentUsers: []
  },

  reducers: {

  },

  extraReducers: (builder) => {
    builder
        .addCase(fetchCompanies.fulfilled, (state, action) => {
          state.companies = action.payload.results
        })

        .addCase(saveCompany.fulfilled, (state, action) => {
          const savedCompany = action.meta.arg
          if(savedCompany.id){
             state.companies = state.companies.map((company) => {
                if( company.id == savedCompany.id ){
                    return action.payload
                }
                return company
             })
          }else{
             state.companies.push(action.payload)
          }
        })

        .addCase(deleteCompany.pending, (state, action) => {
          state.companies = state.companies.filter( (company) => company.id != action.meta.arg )
        })


        .addCase(fetchStudentUsers.fulfilled, (state, action) => {
          state.studentUsers = action.payload
        })


        .addCase(fetchInvoices.fulfilled, (state, action) => {
          state.invoices = action.payload.results
        })

        .addCase(saveInvoice.fulfilled, (state, action) => {
          const savedInvoice = action.meta.arg
          if(savedInvoice.id){
             state.invoices = state.invoices.map((invoice) => {
                if( invoice.id == savedInvoice.id ){
                    return action.payload
                }
                return invoice
             })
          }else{
             state.invoices.push(action.payload)
          }
        })

        .addCase(deleteInvoice.pending, (state, action) => {
           state.invoices = state.invoices.filter( (invoice) => invoice.id != action.meta.arg )
        })

        .addCase(saveInvoiceItem.fulfilled, (state, action) => {
            const savedInvoiceItem = action.meta.arg
            state.invoices = state.invoices.map((invoice) => {
               if( invoice.id == savedInvoiceItem.invoice ){
                   let updatedInvoiceItems = [...invoice.invoice_items]
                   if(savedInvoiceItem.id){
                      updatedInvoiceItems = invoice.invoice_items.map((item) => {
                          if( item.id == savedInvoiceItem.id ){
                             return action.payload
                          }
                          return item
                      })
                   }else{
                       updatedInvoiceItems.push(action.payload)
                   }
                   return Object.assign({}, invoice, {invoice_items: updatedInvoiceItems})
               }

               return invoice
            })
        })

        .addCase(deleteInvoiceItem.pending, (state, action) => {
           const invoiceItem = action.meta.arg
           state.invoices = state.invoices.map((invoice) => {
               if( invoice.id == invoiceItem.invoice ){
                   let updatedInvoiceItems = invoice.invoice_items.filter( i => i.id != invoiceItem.id)
                   return Object.assign({}, invoice, {invoice_items: updatedInvoiceItems})
               }
               return invoice
            })
        })


        .addCase(savePaymentItem.fulfilled, (state, action) => {
            const savedPaymentItem = action.meta.arg
            state.invoices = state.invoices.map((invoice) => {
               if( invoice.id == savedPaymentItem.invoice ){
                   let updatedInvoicePayments = [...invoice.invoice_payments]
                   if(savedPaymentItem.id){
                      updatedInvoicePayments = invoice.invoice_payments.map((payment) => {
                          if( payment.id == savedPaymentItem.id ){
                             return action.payload
                          }
                          return payment
                      })
                   }else{
                       updatedInvoicePayments.push(action.payload)
                   }
                   return Object.assign({}, invoice, {invoice_payments: updatedInvoicePayments})
               }

               return invoice
            })
        })

        .addCase(deletePaymentItem.pending, (state, action) => {
           const paymentItem = action.meta.arg
           state.invoices = state.invoices.map((invoice) => {
               if( invoice.id == paymentItem.invoice ){
                   let updatedInvoicePayments = invoice.invoice_payments.filter( p => p.id != paymentItem.id)
                   return Object.assign({}, invoice, {invoice_payments: updatedInvoicePayments})
               }
               return invoice
            })
        })


      }

})

export default companySlice.reducer