import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const fetchProspects = createAsyncThunk(
  'fetchProspects',
  async (args, thunkAPI) => {
    const {searchQuery, statusFilter, prospectPage} = thunkAPI.getState().admin.prospect
    const response = await fetch(`/api/prospect/?page=${prospectPage}&search=${searchQuery}&status=${statusFilter}`)
    const data = await response.json()
    return data
  }
)


export const emailProspect = createAsyncThunk(
  'emailProspect',
  async (emailData, thunkAPI) => {
    const url = `/api/prospect/send_email/`
    const token = thunkAPI.getState().user.token
    const response = await fetch(
        url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
            body: JSON.stringify(emailData)
        }
    )
    const data = await response.json()
    return data
  }
)


export const saveProspect = createAsyncThunk(
  'saveProspect',
  async (prospect, thunkAPI) => {
    const saveUrl = prospect.id ? `/api/prospect/${prospect.id}/` : `/api/prospect/`
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: prospect.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(prospect)
    })
    const data = await response.json()
    return data
  }
)



export const registerProspect = createAsyncThunk(
  'registerProspect',
  async (prospect, thunkAPI) => {
    const url = `/api/prospect/${prospect.id}/register/`
    const token = thunkAPI.getState().user.token
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const deleteProspect = createAsyncThunk(
  'deleteProspect',
  async (prospectID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/prospect/${prospectID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)


const prospectSlice = createSlice({
  name: 'prospect',

  initialState: {
    searchQuery: '',
    statusFilter: '',

    prospectCount: 0,
    prospectPage: 1,
    prospects: [],
  },

  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload
    },
    setStatusFilter(state, action) {
      state.statusFilter = action.payload
    },
    setProspectPage(state, action) {
      state.prospectPage = action.payload
    },
  },

  extraReducers: (builder) => {
     builder
        .addCase(fetchProspects.fulfilled, (state, action) => {
          state.prospects = action.payload.results
          state.prospectCount = action.payload.count
        })

        .addCase(registerProspect.fulfilled, (state, action) => {
          const registeredProspect = action.meta.arg
          state.prospects = state.prospects.map((prospect) => {
                if( prospect.id == registeredProspect.id ){
                    let updatedProspect = Object.assign({}, prospect, {student: 1})
                    return updatedProspect
                }
                return prospect
            })
        })

        .addCase(saveProspect.fulfilled, (state, action) => {
          const savedProspect = action.meta.arg
          if(savedProspect.id){
             state.prospects = state.prospects.map((prospect) => {
                if( prospect.id == savedProspect.id ){
                    return action.payload
                }
                return prospect
             })
          }else{
             state.prospects = [action.payload, ...state.prospects]
          }
        })

        .addCase(deleteProspect.pending, (state, action) => {
          state.prospects = state.prospects.filter( (prospect) => prospect.id != action.meta.arg )
        })

  }

})

export const { setSearchQuery, setStatusFilter, setProspectPage } = prospectSlice.actions
export default prospectSlice.reducer