import { combineReducers } from 'redux'
import company from '../packages/company/_slice'
import document from '../packages/document/_slice'
import page from '../packages/page/_slice'
import prospect from '../packages/prospect/_slice'
import program from '../packages/program/_slice'
import nclex from '../packages/nclex/_slice'

export default combineReducers({
   company,
   document,
   prospect,
   page,
   program,
   nclex
})