import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const CompanyDashboard = loadable(() => import("./CompanyDashboard.js"));
const CompanyDetailDashboard = loadable(() => import("./CompanyDetailDashboard.js"));


export default [
    <Route exact path="/admin/company/" element={<CompanyDashboard/>} />,
    <Route exact path="/admin/company/:companyID/" element={<CompanyDetailDashboard/>} />,
]