import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'



export const fetchSubCategories = createAsyncThunk(
  'fetchSubCategories',
  async (categoryID, thunkAPI) => {
    const response = await fetch(`/api/nclex_sub_category/?category=${categoryID}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchCategoryCompletion = createAsyncThunk(
  'fetchCategoryCompletion',
  async (categoryID, thunkAPI) => {
    const response = await fetch(`/api/nclex_category/fetch_category_completion/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)


export const fetchSubCategoryCompletion = createAsyncThunk(
  'fetchSubCategoryCompletion',
  async (categoryID, thunkAPI) => {
    const response = await fetch(`/api/nclex_category/${categoryID}/fetch_subcategory_completion/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const fetchSubCategoryQuestion = createAsyncThunk(
  'fetchSubCategoryQuestion',
  async (subcategoryID, thunkAPI) => {
    const response = await fetch(`/api/nclex_sub_category/${subcategoryID}/fetch_question/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
    })
    const data = await response.json()
    return data
  }
)



export const updateNCLEXQuestionProgress = createAsyncThunk(
  'updateNCLEXQuestionProgress',
  async (question, thunkAPI) => {
    const response = await fetch(`/api/nclex_sub_category/update_nclex_question_progress/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${thunkAPI.getState().user.token}`
        },
        body: JSON.stringify(question)
    })

    const data = await response.json()
    return data
  }
)


const nclexSlice = createSlice({
  name: 'nclex',

  initialState: {
    subcategories: [],
  },

  reducers: {

  },

  extraReducers: (builder) => {
    builder
        .addCase(fetchSubCategories.fulfilled, (state, action) => {
           state.subcategories = action.payload
        })
  }

})

export default nclexSlice.reducer