import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const RangeDashboard = loadable(() => import("./RangeDashboard.js"))


export default [
    <Route exact path="/admin/range/" element={<RangeDashboard/>} />,
]