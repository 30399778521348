import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';

const HomeDashboard = loadable(() => import("./HomeDashboard.js"));

const BookDashboard = loadable(() => import("./BookDashboard.js"));
const BookDetailDashboard = loadable(() => import("./BookDetailDashboard.js"));
const ChapterReaderDashboard = loadable(() => import("./ChapterReaderDashboard.js"));

const LessonDashboard = loadable(() => import("./LessonDashboard.js"));
const LessonDetailDashboard = loadable(() => import("./LessonDetailDashboard.js"));

const AssessmentDashboard = loadable(() => import("./AssessmentDashboard.js"));
const AssessmentVideoDashboard = loadable(() => import("./AssessmentVideoDashboard.js"));
const AssessmentReviewDashboard = loadable(() => import("./AssessmentReviewDashboard.js"));

const GradeDashboard = loadable(() => import("./GradeDashboard.js"));

const PracticeDashboard = loadable(() => import("./PracticeDashboard.js"));
const PracticeDetailDashboard = loadable(() => import("./PracticeDetailDashboard.js"));

const BillingDashboard = loadable(() => import("./BillingDashboard.js"));

const DocumentDashboard = loadable(() => import("./DocumentDashboard.js"));
const LectureDashboard = loadable(() => import("./LectureDashboard.js"));
const ClinicalDashboard = loadable(() => import("./ClinicalDashboard.js"));

const NCLEXCategoryDashboard = loadable(() => import("./NCLEXCategoryDashboard.js"));
const NCLEXCategoryDetailDashboard = loadable(() => import("./NCLEXCategoryDetailDashboard.js"));
const NCLEXCategoryExamDashboard = loadable(() => import("./NCLEXCategoryExamDashboard.js"));

const RangeDashboard = loadable(() => import("./RangeDashboard.js"));

export default [
    <Route exact path="/student/" element={<HomeDashboard/>} />,

    <Route exact path="/student/book/" element={<BookDashboard/>} />,
    <Route exact path="/student/book/:bookID/" element={<BookDetailDashboard/>} />,
    <Route exact path="/student/book/:bookID/chapter/:chapterID/" element={<ChapterReaderDashboard/>} />,

    <Route exact path="/student/lesson/" element={<LessonDashboard/>} />,
    <Route exact path="/student/lesson/:lessonID/" element={<LessonDetailDashboard/>} />,

    <Route exact path="/student/assessment/:assessmentID/" element={<AssessmentDashboard/>} />,
    <Route exact path="/student/assessment-video/:assessmentID/" element={<AssessmentVideoDashboard/>} />,
    <Route exact path="/student/assessment-review/:assessmentID/" element={<AssessmentReviewDashboard/>} />,

    <Route exact path="/student/grade/" element={<GradeDashboard/>} />,

    //<Route exact path="/student/practice/" element={<PracticeDashboard/>} />,
    //<Route exact path="/student/practice/:chapterID/" element={<PracticeDetailDashboard/>} />,

    <Route exact path="/student/document/" element={<DocumentDashboard/>} />,
    <Route exact path="/student/lecture/" element={<LectureDashboard/>} />,
    <Route exact path="/student/clinical/" element={<ClinicalDashboard/>} />,

    <Route exact path="/student/billing/" element={<BillingDashboard/>} />,

    <Route exact path="/student/nclex/" element={<NCLEXCategoryDashboard/>} />,
    <Route exact path="/student/nclex/:categoryID/" element={<NCLEXCategoryDetailDashboard/>} />,
    <Route exact path="/student/nclex/:categoryID/:subcategoryID/" element={<NCLEXCategoryExamDashboard/>} />,

    <Route exact path="/student/range/" element={<RangeDashboard/>} />

]