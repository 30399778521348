import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const fetchDocuments = createAsyncThunk(
  'fetchDocuments',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().user.token

    const response = await fetch(`/api/document/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })

    const data = await response.json()
    return data
  }
)


export const saveDocument = createAsyncThunk(
  'saveDocument',
  async (document, thunkAPI) => {
    const saveUrl = document.id ? `/api/document/${document.id}/` : `/api/document/`
    const saveMethod = document.id ? 'PUT' : 'POST'
    const token = thunkAPI.getState().user.token
    const response = await fetch(saveUrl, {
        method: saveMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify(document)
    })
    const data = await response.json()
    return data
  }
)


export const deleteDocument = createAsyncThunk(
  'deleteDocument',
  async (documentID, thunkAPI) => {
    const token = thunkAPI.getState().user.token
    const response = await fetch(`/api/document/${documentID}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    })
    const data = await response.json()
    return data
  }
)



const documentSlice = createSlice({
  name: 'document',

  initialState: {
    documents: [],
  },

  reducers: {

  },

  extraReducers: (builder) => {
    builder
        .addCase(fetchDocuments.fulfilled, (state, action) => {
          state.documents = action.payload.results
        })

        .addCase(saveDocument.fulfilled, (state, action) => {
          const savedDocument = action.meta.arg
          if(savedDocument.id){
             state.documents = state.documents.map((document) => {
                if( document.id == savedDocument.id ){
                    return action.payload
                }
                return document
             })
          }else{
             state.documents.push(action.payload)
          }
        })

        .addCase(deleteDocument.pending, (state, action) => {
          state.documents = state.documents.filter( (document) => document.id != action.meta.arg )
        })

  }

})

export default documentSlice.reducer