import React from 'react';
import loadable from "@loadable/component";
import {Route} from 'react-router-dom';


const DocumentDashboard = loadable(() => import("./DocumentDashboard.js"));


export default [
    <Route exact path="/admin/document/" element={<DocumentDashboard/>} />,
]